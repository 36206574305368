var tests = function () {
    var steps = {};
    var page = 0;
    var pages = 1;
    var url = $('.test:not(.testV2)').data('url')

    if (!url) return;
    $('.test').show();

    function setStep(step) {
        $('.testQuestions .test-title').html(step.name);
        var btns = step.vars.map(function (v) {
            return createBtn(v)
        })
        page++;
        $('.testQuestions .test-pagenav-page').html(page)
        $('.testQuestions .test-pagenav-pages').html(pages)
        $('.testQuestions .test-answers').html('').append(...btns);
    }

    function createBtn(val) {
        var el = document.createElement('button');
        el.className = "btn btn--white btn--tt_none";
        el.innerHTML = val.name;
        el.addEventListener('click', function (e) {
            e.preventDefault();
            if (val.step in steps) {
                setStep(steps[val.step]);
            } else {
                $('.testQuestions').hide();
                $(`.test .testEnding[data-end="${val.step}"]`).show()
            }
        })
        return el;
    }

    $.getJSON(url, {}, function (data) {
        $('.testHomepage .test-first-title').html(data.name);
        $('.testHomepage .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testHomepage').hide();
            setStep(steps[data.start]);
            $('.testQuestions').show();
        });
        $('.testEnding .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testEnding').hide();
            $('.testHomepage').show();
        });

    })
}

var testsV2 = function () {
    var steps = {};
    var page = 0;
    var pages = 1;
    var url = $('.test.testV2').data('url');
    var history = [];
    var vars = {};

    if (!url) return;
    $('.test.testV2').show();

    function setStep(step) {
        $('.testQuestions .test-title').html(step.name);
        vars = {};
        var btns = step.vars.map(function (v, i) {
            return createBtn(i, v, step['multiple']);
        })
        if (step['multiple']) {
            btns.push(createBtnNext());
        }
        console.log(history);
        if(history.length > 1){
            btns.unshift(createBtnPrev());
        }
        page++;
        $('.testQuestions .test-pagenav-page').html(page);
        $('.testQuestions .test-pagenav-pages').html(pages);
        $('.testQuestions .test-answers').html('').append(...btns);
    }

    function createBtn(i, val, multiple = false) {
        var el = document.createElement('button');
        el.className = "btn btn--white btn--tt_none";
        el.innerHTML = val.name;
        el.addEventListener('click', function (e) {
            e.preventDefault();
            if (!multiple) {
                console.log('!multiple')
                if (val.step in steps) {
                    history.push(val.step);
                    setStep(steps[val.step]);
                    console.log('val.step in steps')
                } else {
                    $('.test .testQuestions').hide();
                    $(`.test .testEnding[data-end="${val.step}"]`).show();
                }
            } else {
                if (!el.classList.contains('active')) {
                    vars[i] = val.step;
                } else {
                    delete vars[i];
                }
                el.classList.toggle('active');
            }

        })
        return el;
    }

    function createBtnNext() {
        var el = document.createElement('button');
        el.className = 'btn btn--next';
        el.innerHTML = 'Далее';
        el.addEventListener('click', function (e) {
            e.preventDefault();
            let first = false;
            for (let key in vars) {
                if (first === false) {
                    first = vars[key]
                }
                if (!(vars[key] in steps)) {
                    $('.test .testQuestions').hide();
                    $(`.test .testEnding[data-end="${vars[key]}"]`).show();
                    return;
                }
            }
            if (first !== false) {
                history.push(first);
                setStep(steps[first]);
            }

        })
        return el;
    }
    function createBtnPrev() {
        var el = document.createElement('button');
        el.className = "btn btn--prev";
        el.innerHTML = 'Назад';
        el.addEventListener('click', function (e) {
            e.preventDefault();
            history.pop();
            page -= 2;
            setStep(steps[history[history.length - 1]]);
        })
        return el;
    }

    $.getJSON(url, {}, function (data) {
        $('.testHomepage .test-first-title').html(data.name);
        $('.testHomepage .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            vars = {};
            history = [data.start];
            $('.testHomepage').hide();
            setStep(steps[data.start]);
            $('.testQuestions').show();
        });
        $('.testEnding .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            vars = {};
            history = [];
            $('.testEnding').hide();
            $('.testHomepage').show();
        });

    })
}

var testsDir = function () {
    var steps = {};
    var page = 0;
    var pages = 1;
    var url = $('.tests-dir').data('url');

    if (!url) return;
    $('.tests-test').show();

    //console.log("12345");


    function setStep(step) {
        //console.log(step);
        $('.testQuestions .tests-test-question').html(step.name);
        var btns = step.vars.map(function (v) {
            if (step.multi == "Y") {
                return createMultiBtn(v)
            } else return createBtn(v)
        });
        if (step.multi == "Y") {
            var el = document.createElement('div');
            el.className = "ans-button ans-next";
            el.innerHTML = '<a href="#" class="tests-test-answer tests-test-answer--center">\n' +
                '                                <div class="tests-test-answer-text">Далее</div>\n' +
                '                            </a>';
            el.addEventListener('click', function (e) {
                e.preventDefault();
                var fl = false;
                var nstep = step.nextstep;
                $(this).parents(".ans-wrap").find(".ans-button.sel").each(function () {
                    fl = true;
                    if ($(this).find("a").attr("data-dop") != "undefined") {
                        $("div[data-end='end-final']").find("." + $(this).find("a").attr("data-dop")).show();
                    }
                    if ($(this).find("a").attr("data-end") == 'y') {
                        nstep = $(this).find("a").attr("data-step");
                    }
                });
                if (!fl) return false;

                //console.log(nstep);
                if (nstep in steps) {
                    setStep(steps[nstep]);
                } else {
                    $('.testQuestions').hide();
                    $(`.tests-test .testEnding[data-end="${nstep}"]`).show()
                }
            })
        }
        page++;
        $('.testQuestions .tests-test-pager__start').html(page)
        $('.testQuestions .tests-test-pager__end').html(pages)
        $('.testQuestions .tests-test-answers .answers-body').html('').append(...btns);
        $(".ans-wrap").append(el);
    }

    function createMultiBtn(val) {
        var el = document.createElement('div');
        el.className = "ans-button";
        var end = "n";
        if (val.step.indexOf("end") != -1) {
            end = "y";
        }
        el.innerHTML = '<a href="#" data-end="' + end + '" data-step="' + val.step + '" data-dop="' + val.dop + '" class="tests-test-answer tests-test-answer--center">\n' +
            '                                <div class="tests-test-answer-text">' + val.name + '</div>\n' +
            '                            </a>';
        el.addEventListener('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass("sel")) {
                $(this).removeClass("sel");
            } else {
                $(this).addClass("sel");
            }
        })
        return el;
    }

    function createBtn(val) {
        var el = document.createElement('div');
        el.className = "ans-button";
        el.innerHTML = '<a href="#" class="tests-test-answer tests-test-answer--center">\n' +
            '                                <div class="tests-test-answer-text">' + val.name + '</div>\n' +
            '                            </a>';
        el.addEventListener('click', function (e) {
            e.preventDefault();
            if (val.dop) $("." + val.dop).show();
            if (val.step in steps) {
                setStep(steps[val.step]);
            } else {
                $('.testQuestions').hide();
                $(`.tests-test .testEnding[data-end="${val.step}"]`).show()
            }
        })
        return el;
    }

    //console.log(url);
    $.getJSON(url, {}, function (data) {
        //console.log(data);
        $('.tests-test-title').html(data.name);
        steps = data.steps;
        page = 0;
        pages = data.stepsCount;
        setStep(steps[data.start]);
        $('.testQuestions').show();
        /*$('.testHomepage .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testHomepage').hide();
            setStep(steps[data.start]);
            $('.testQuestions').show();
        });*/
        $('.testEnding .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testEnding').hide();
            $('.testHomepage').show();
        });

    })
}

var testsCalc = function () {
    var steps = {};
    var page = 0;
    var pages = 1;
    var url = $('.tests-calc').data('url');
    var points = 0;

    if (!url) return;
    $('.tests-calc').show();

    //console.log("12345");


    function setStep(step) {
        console.log(step);
        $('.testQuestions .tests-test-question').html(step.name);
        var btns = step.vars.map(function (v) {
            return createBtn(v)
        })
        page++;
        $('.testQuestions .tests-test-pager__start').html(page)
        $('.testQuestions .tests-test-pager__end').html(pages)
        $('.testQuestions .tests-test-answers .answers-body').html('').append(...btns);
    }

    function createBtn(val) {
        var el = document.createElement('div');
        el.className = "ans-button";
        el.innerHTML = '<a href="#" class="tests-test-answer tests-test-answer--center">\n' +
            '                                <div class="tests-test-answer-text">' + val.name + '</div>\n' +
            '                            </a>';
        el.addEventListener('click', function (e) {
            e.preventDefault();
            if (val.points) points += val.points * 1;
            if (val.step in steps) {
                setStep(steps[val.step]);
            } else {
                $('.testQuestions').hide();
                $(".test-res-var").each(function (index) {
                    console.log(points);
                    if ($(this).attr("data-min") <= points) {
                        console.log($(this).attr("data-min"));
                        console.log(points);
                        $(this).show();
                        return false;
                    }
                });
                $(`.tests-calc .testEnding[data-end="${val.step}"]`).show()
            }
        })
        return el;
    }

    //console.log(url);
    $.getJSON(url, {}, function (data) {
        console.log(data);
        $('.tests-test-title').html(data.name);
        steps = data.steps;
        page = 0;
        pages = data.stepsCount;
        setStep(steps[data.start]);
        $('.testQuestions').show();
        /*$('.testHomepage .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testHomepage').hide();
            setStep(steps[data.start]);
            $('.testQuestions').show();
        });*/
        $('.testEnding .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testEnding').hide();
            $('.testHomepage').show();
        });

    })
}

var testsCats = function () {
    var steps = {};
    var page = 0;
    var pages = 1;
    var url = $('.tests-cats').data('url');
    var imgPath = "/local/templates/valemidin2021/dist/img/cats/";
    var ans = "";

    var oRes = {
        "1111": "1",
        "1112": "2",
        "1113": "1",
        "1121": "1",
        "1122": "2",
        "1123": "1",
        "1131": "1",
        "1132": "1",
        "1133": "1",
        "1211": "2",
        "1212": "2",
        "1213": "2",
        "1221": "2",
        "1222": "2",
        "1223": "2",
        "1231": "1",
        "1232": "2",
        "1233": "1",
        "1311": "2",
        "1312": "2",
        "1313": "2",
        "1321": "2",
        "1322": "2",
        "1323": "2",
        "1331": "1",
        "1332": "2",
        "1333": "1",
        "2111": "3",
        "2112": "1",
        "2113": "1",
        "2121": "1",
        "2122": "1",
        "2123": "1",
        "2131": "3",
        "2132": "1",
        "2133": "1",
        "2211": "1",
        "2212": "1",
        "2213": "1",
        "2221": "1",
        "2222": "1",
        "2223": "1",
        "2231": "1",
        "2232": "1",
        "2233": "1",
        "2311": "1",
        "2312": "1",
        "2313": "1",
        "2321": "1",
        "2322": "1",
        "2323": "1",
        "2331": "1",
        "2332": "1",
        "2333": "1",
        "3111": "3",
        "3112": "1",
        "3113": "1",
        "3121": "1",
        "3122": "1",
        "3123": "1",
        "3131": "3",
        "3132": "1",
        "3133": "1",
        "3211": "1",
        "3212": "1",
        "3213": "1",
        "3221": "1",
        "3222": "1",
        "3223": "1",
        "3231": "1",
        "3232": "1",
        "3233": "1",
        "3311": "1",
        "3312": "1",
        "3313": "1",
        "3321": "1",
        "3322": "1",
        "3323": "1",
        "3331": "1",
        "3332": "1",
        "3333": "1"
    }

    //console.log(url);

    if (!url) return;
    $('.tests-cats').show();


    function setStep(step) {
        //console.log(step);
        $('.tests-test-question').html(step.name);
        var btns = step.vars.map(function (v) {
            return createBtn(v)
        })
        page++;
        $('.tests-test-pager__start').html(page)
        $('.tests-test-pager__end').html(pages)
        $('.tests-test-answers-inner').html('').append(...btns);
    }

    function createBtn(val) {
        var el = document.createElement('div');
        el.className = "col-12 col-md-4";
        el.innerHTML = '<a href="#" data-id="' + val.id + '" class="tests-test-answer tests-test-answer--center">\n' +
            '              <div class="tests-test-answer-img" style="background-image: url(' + imgPath + val.img + ')"></div>\n' +
            '                                <div class="tests-test-answer-text">' + val.name + '</div>\n' +
            '                            </a>';
        el.addEventListener('click', function (e) {
            ans += $(this).find("a").attr("data-id");
            e.preventDefault();
            if (val.step in steps) {
                setStep(steps[val.step]);
            } else {
                //console.log(ans);
                //console.log(oRes[ans]);
                $(".tests-cats .tests-test-title").hide();
                $('.testQuestions').hide();
                $(".tests-cats").addClass("tests-cats-result");
                var resId = oRes[ans];
                if (resId == 1) {
                    resId = "end1";
                } else if (resId == 2) {
                    resId = "end2";
                } else if (resId == 3) {
                    resId = "end3";
                }
                //console.log(resId);
                $(`.testEnding[data-end="${resId}"]`).show()
            }
        })
        return el;
    }

    //console.log(url);
    $.getJSON(url, {}, function (data) {
        //console.log(data);
        //console.log("12345678");
        //$('.tests-test-title').html(data.name);
        steps = data.steps;
        page = 0;
        pages = data.stepsCount;
        setStep(steps[data.start]);
        $('.testQuestions').show();
        /*$('.testHomepage .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testHomepage').hide();
            setStep(steps[data.start]);
            $('.testQuestions').show();
        });*/
        $('.testEnding .testStart').on('click', function (e) {
            e.preventDefault();
            steps = data.steps;
            page = 0;
            pages = data.stepsCount;
            $('.testEnding').hide();
            $('.testHomepage').show();
        });

    })
}